$(document).ready(function() {


  $.scroll_to_notices = function(scrollElement) {
    if(scrollElement.length) {
      $('html, body').animate({
        scrollTop: (scrollElement.offset().top - 230)
      }, 900);
    }
  };


  $('.submit').on('click', function(e) {
    e.preventDefault();
    if ($('.configurator-form').valid()) {
      var data = $('.configurator-form').serialize();
      sendfullMessage(data, true);
    }
  });

  $(".quantity").on("click", "button.quantity__plus, button.quantity__minus", function() {
    plusminus($(this))
  });

  

  mainMenu();
  getCurrentYear();
  callProductPopup();
  scrollAfterSelectPaymentChannel();
  if ($("#mainSlider").length > 0) {
    mainSlider($("#mainSlider"));
  }

  if ($("#referencesSlider").length > 0) {
    referencesSlider($("#referencesSlider"));
  }
  if ($("#certGallery").length > 0) {
    certSlider($("#certGallery"));
  }

  if ($("#referencesBigSlider").length > 0) {
    referencesBigSlider($("#referencesBigSlider"));
  }

  // if ($(".woocommerce-product-gallery__wrapper").length > 0) {
  //   productGallery();
  // }


  showShipmentFieldsWoocommerce();
  toggleCompany();
  showSmallCart();


$('.product-gallery').lightGallery({
  thumbnail: true,
  getCaptionFromTitleOrAlt: false,
  download: false,
  autoplay: false,
  share: false,
  zoom: false,
  autoplayControls: false,
  fullScreen: false
}); 

$('#certGallery .slick-track').lightGallery({
  thumbnail: true,
  getCaptionFromTitleOrAlt: false,
  download: false,
  autoplay: false,
  share: false,
  zoom: false,
  autoplayControls: false,
  fullScreen: false
}); 





  scrolling();
  scrollToTop()
 

  configuratorForm();
  $('select').each(function() {
    $(this).find('option:eq(0)').attr("disabled", "true");
  });

  $('select').niceSelect();
  activePartOfForm();
  showConfiguratorPreview();
  toggleCategoryDescription();



});


$(window).on("load", function() {
  referencesMsnry();
});

// $(window).scroll(function (event) {
//   var $navBar = $('#productNavBar');
//   var $menuHeight = $('.page-header').outerHeight();
//   var $offsetNavBar = $navBar.offset().top
//   var $scroll = $(window).scrollTop() + $menuHeight;
//   if ($scroll >= $offsetNavBar) {
//     $navBar.addClass('product-nav--fixed');
//   } else {
//     $navBar.removeClass('product-nav--fixed');
//   }
// });




plusminus = function(e) {
  var i = e.closest(".quantity").find(".qty"),
      s = parseFloat(i.val()),
      n = parseFloat(i.attr("max")),
      o = parseFloat(i.attr("min")),
      t = parseFloat(i.attr("step"));
  e.is(".quantity__plus") ? n && n <= s ? i.val(n) : i.val(s + t) : o && s <= o ? i.val(o) : 1 < s && i.val(s - t), i.trigger("change")
};


function sendfullMessage(data){
  $.ajax({
    type: "POST",
    url: "https://powercase.pl/send_configurator.php",
    dataType: "json",
    data: data,
    beforeSend: function() {
      $(".submit").attr("disabled", true);
      $('#configuratorLoader').show();
    },
    success: function() {
      setTimeout(function() {
        $('#configuratorLoader').hide();
        $('html, body').animate( {
          scrollTop: $(".configurator-form").offset().top - 200
        }, 800);
        $(".submit").attr("disabled", false);
        $('.configurator-form')[0].reset();
        $('form.configurator-form select').val('');

      }, 2000);


      $('.statement').text('Dziękujemy! Twoja konfiguracja została wysłana.');

    }
  });
}


function mainMenu() {
  var $burger = $("#burgerButton");
  var $nav = $("#siteMenu");
  var $childMenu = $(".main-menu .menu-item-has-children > a")
  $childMenu.append("<span class='mobile-menu-arrow'></span>");
  $('.mobile-menu-arrow').on('click', function(e) {
    e.preventDefault();
    $(this).toggleClass('mobile-menu-arrow--active')
    $(this).parent().next().slideToggle('medium');
  });
  $burger.on("click", function(e) {
    e.preventDefault();
    $(this).toggleClass('mobile-burger--active');
    $nav.toggleClass('main-menu--active');
  });
}


function showConfiguratorPreview() {
  var $showConfBtn = $("#showConfiguratorPreview");
  var $confTable = $('.configuration-preview')
  $showConfBtn.on("click", function(e) {
    e.preventDefault();
    $(this).toggleClass('show-configurator-preview--close');
    $confTable.toggleClass('configuration-preview--active');
    if ($(this).hasClass('show-configurator-preview--close')){
      $(this).text('Wróć do konfiguratora'); 
    } else {
      $(this).text('Zobacz swoją konfiguracje'); 
    }
  });
}

function toggleCategoryDescription() {
  var $toggleBtn = $("#toggleCategoryDesc");
  var $descContent = $(".category-description__content");
  $toggleBtn.on("click", function(e) {
    e.preventDefault();
    $descContent.slideToggle('medium');
  });
}


var mainSlider = function(el) {
  el.slick({
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    accessibility: false,
    arrows: false,
  });
};

function productGallery() {
  $('.woocommerce-product-gallery__wrapper a').simpleLightbox();
}

function getCurrentYear() {
  var currentYear = new Date().getFullYear();
  $("#currentYear").html(currentYear);
}

var referencesSlider = function(el) {
  el.slick({
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    dots: false,
    accessibility: false,
    arrows: false,
    infinite: false,
    variableWidth: false,
    focusOnSelect: false,
    initialSlide: 2,
    autoplay: true,
    slide: '.slider-references__item',
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          centerPadding: '60px',
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '30px',
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        }
      },
    ]
  });
};

var certSlider = function(el) {
  el.slick({
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 0,
    fade: false,
    cssEase: "linear",
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1120,
        settings: {
          dots: false,
          infinite: true,
          autoplay: true,
          speed: 10000,
          autoplaySpeed: 0,
          fade: false,
          cssEase: "linear",
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        }
      },
      {
        breakpoint: 868,
        settings: {
          dots: false,
          infinite: true,
          autoplay: true,
          speed: 10000,
          autoplaySpeed: 0,
          fade: false,
          cssEase: "linear",
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        }
      },
      {
        breakpoint: 576,
        settings: {
          dots: false,
          infinite: true,
          autoplay: true,
          speed: 10000,
          autoplaySpeed: 0,
          fade: false,
          cssEase: "linear",
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        }
      }
    ]
  });
};

var referencesBigSlider = function(el) {
  el.slick({
    slidesPerRow: 4,
    rows: 2,
    autoplay: true,
    autoplaySpeed: 8000,
    dots: false,
    accessibility: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesPerRow: 3,
          rows: 2,
          autoplay: true,
          autoplaySpeed: 8000,
          dots: false,
          accessibility: false,
          arrows: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesPerRow: 2,
          rows: 2,
          autoplay: true,
          autoplaySpeed: 8000,
          dots: false,
          accessibility: false,
          arrows: false,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesPerRow: 1,
          rows: 2,
          autoplay: true,
          autoplaySpeed: 8000,
          dots: false,
          accessibility: false,
          arrows: false,
        }
      }
    ]
  });
};

function scrollAfterSelectPaymentChannel() {
  var menuHeight = $('.page-header').outerHeight();
  $('body').on('click', '.channel-container', function() {
    console.log('test');
    $("html, body").animate({
        scrollTop: $('#payment').offset().top - menuHeight
    }, 1000);
  });
}


function scrolling() {
  $(document).on('click', 'a[href^="#"]', function(e) {
    var id = $(this).attr('href');
    var menuHeight = $('.page-header').outerHeight();
    var $id = $(id);
    if ($id.length === 0) {
      return;
    }
    e.preventDefault();
    var pos = $id.offset().top - menuHeight;
    $("html, body").animate(
      { scrollTop: pos }, 1000
    );
  });
}

function scrollToTop() {
  $('#topScroll').on('click', function(e) {
    e.preventDefault();
    $("html, body").animate(
      { scrollTop: 0 }, 1000
    );
  });
}





function configuratorForm() {
  $('.configurator-form :input').on('change', function(e) {
    showHiddenFields();
    var $value = [];
    $dataName = $(this).attr('data-target');
    if ($(this).is(':radio')) {
      $value.push($(this).val());
    } else if ($(this).is(':checkbox')) {
      $('input[data-target="' + $dataName + '"]:checked').each(function(i){
        $value.push($(this).val());         
      })
    } else {
      $value.push($(this).val());
    }
    $value = $value.join(', ');
    $currentRow = $('.configuration-preview').find("#" + $dataName);
    $place = $('.configuration-preview').find("#" + $dataName);
    $place.empty();
    $place.typeChar({
      html: $('<span>'+ ' ' + $value + ' ' +'</span>'),
      completed: $.noop
    });
 
 
  });
}

function showHiddenFields() {
  $emergencyPower = $('input[name=emergency_power]:checked').val();
  $color = $('input[name=color]:checked').val();
  if ($emergencyPower == 'Tak') {
    $('.configurator-form').find('.emergency-power').removeClass('configurator-form__group--hidden');
  } else {
    $('.configurator-form').find('.emergency-power').addClass('configurator-form__group--hidden');
  }
  $('[name="metal_shelves[]"]').on('click', function(e) {
    if ($(this).is(':checked')) {
      $(this).parent().next().removeClass('configurator-form__group--hidden');
    } else {
      $(this).parent().next().addClass('configurator-form__group--hidden');
    }
  });
  if ($color == 'Inny') {
    $('.configurator-form').find('.color-text').removeClass('configurator-form__group--hidden');
  } else {
    $('.configurator-form').find('.color-text').addClass('configurator-form__group--hidden');
  }
  $('[name="power[]"]').on('click', function(e) {
    if ($(this).is(':checked')) {
      // $('.configurator-form').find('.'+$('[name="power[]"]:checked').attr('id')).removeClass('configurator-form__group--hidden');
      $(this).parent().next().removeClass('configurator-form__group--hidden');
    } else {
      // $('.configurator-form').find('.'+$('[name="power[]"]:checked').attr('id')).addClass('configurator-form__group--hidden');
      $(this).parent().next().addClass('configurator-form__group--hidden');
    }
  });
}





function activePartOfForm() {
  $('.configurator-form__select').on('change', function() {
    $(this).valid();
  });
  jQuery.validator.addClassRules('required', {
    required: true,
  });
  $.validator.addMethod("required", $.validator.methods.required,"To pole jest wymagane!");
  $('.configurator-form').validate({
    errorPlacement: function(error, element) {
      error.appendTo( element.parent().parent() );
    },
    errorElement: "span",
    debug: true,
    ignore: [],
  });
  
    $('.configurator-form__validate-btn').on('click', function() {
      var currentSection = $(this).closest(".configurator-form__section");
      var fields = currentSection.find(":input");
      var nextSection = currentSection.next('.configurator-form__section--disabled');
      var currentCircle = currentSection.find('.circle');
      if (fields.valid()) {
        $(this).css('opacity', '0');
        currentSection.removeClass('configurator-form__section--active');
        currentSection.addClass('configurator-form__section--completed');
        currentCircle.addClass('circle--completed');
        nextSection.removeClass('configurator-form__section--disabled').addClass('configurator-form__section--active').find('.configurator-form__input, .configurator-form__select, .configurator-form__textarea').prop('disabled', false);
        nextSection.find('button').removeAttr('disabled');
        $('html, body').animate({
          scrollTop: nextSection.offset().top - 150
          }, 1400);
      } else {
        $('html, body').animate({
          scrollTop: currentSection.offset().top - 150
          }, 900);
      }
  });
}

function referencesMsnry() {
var $container = $('.references-list');
$container.imagesLoaded(function(){
    $container.masonry({
         itemSelector: '.slider-references__item',
    columnWidth: '.grid-sizer',
    percentPosition: true,
    gutter: 40
    });
}); 
$container.addClass('references-list--loaded');
}


function showShipmentFieldsWoocommerce() {
  $('#ship-to-different-address-checkbox').on('change', function() {
    $('.shipping-address').slideToggle();  
  });
}


function toggleCompany() {

}


function flashCart() {
  $('.add_to_cart_button').on('click', function(){
    $('.small-cart').addClass('small-cart--flash').delay(3000).queue(function(){
      $(this).removeClass('small-cart--flash').dequeue();
    });
  });
}

function showSmallCart() {
  $('.cart-header').on('click', function() {
    $('.small-cart').toggleClass('small-cart--active');
  });
}


function callProductPopup() {
  if ($(".product-call-form").length > 0) {
    var $productName = $('.single-product__title').text();
    $('#callProductName').val($productName);
  }
}